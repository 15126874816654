<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">Session Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Session Name -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Name" label-for="session-name">
            <b-form-input id="session-name" required v-model="sessionDataInfo.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Visibility -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Visibility" label-for="session-visibility">
            <v-select
              v-model="sessionDataInfo.visibility"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="visibilityOptions"
              :clearable="false"
              required
              input-id="session-visibility"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Status" label-for="session-status">
            <v-select
              v-model="sessionDataInfo.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              required
              input-id="session-status"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useSessionsList from '../sessions-list/useSessionsList';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    sessionData: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    const title = this.sessionDataInfo.name ? (`${this.sessionDataInfo.name} - Edit`) : 'Edit Session';
    return {
      title,
    };
  },
  watch: {
    sessionData: function () {
      this.inheritSelects();
    },
  },
  methods: {
    inheritSelects() {
      if (this.sessionData.status && !this.sessionData.status.value) {
        this.sessionDataInfo.status = this.statusOptions.filter((s) => s.value === this.sessionData.status)[0];
      }

      if (this.sessionData.visibility && !this.sessionData.visibility.value) {
        this.sessionDataInfo.visibility = this.visibilityOptions.filter((s) => s.value === this.sessionData.visibility)[0];
      }
    },
    doSaveChanges() {
      const pwd = this.sessionDataInfo;

      const pl = {
        name: pwd.name,
        visibility: pwd.visibility.value,
        status: pwd.status.value,
      };

      store
        .dispatch('app-roster/updateSession', { sessionId: pwd.id, sessionData: pl })
        .then((response) => {
          this.$emit('update:session-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Session Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit session',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.inheritSelects();
  },
  setup(props) {
    const sessionDataInfo = ref(props.sessionData);

    const SESSION_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(SESSION_APP_STORE_MODULE_NAME))
      store.registerModule(SESSION_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SESSION_APP_STORE_MODULE_NAME)) store.unregisterModule(SESSION_APP_STORE_MODULE_NAME);
    });

    const { statusOptions, visibilityOptions } = useSessionsList();

    return {
      sessionDataInfo,
      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
